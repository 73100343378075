@font-face {
    font-family: 'Roboto';
	font-weight: 400;
	font-style: normal;
    src: url('../fonts/Roboto-Regular-webfont.eot');
    src: url('../fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Regular-webfont.woff') format('woff'),
    url('../fonts/Roboto-Regular-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
}
@font-face {
    font-family: 'Roboto';
	font-weight: 500;
	font-style: normal;
    src: url('../fonts/Roboto-Medium-webfont.eot');
    src: url('../fonts/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Medium-webfont.woff') format('woff'),
    url('../fonts/Roboto-Medium-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-Medium-webfont.svg#robotomedium') format('svg');
}
@font-face {
    font-family: 'Roboto';
	font-weight: 700;
	font-style: normal;
    src: url('../fonts/Roboto-Bold-webfont.eot');
    src: url('../fonts/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Bold-webfont.woff') format('woff'),
    url('../fonts/Roboto-Bold-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-Bold-webfont.svg#robotobold') format('svg');
}
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(../fonts/MaterialIcons-Regular.woff) format('woff'),
    url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    src: local('Signika'), local('Signika-Regular'), url(../fonts/chart_signika_400.woff) format('woff');
}

@font-face {
    font-family: 'Signika';
    font-style: normal;
    font-weight: 700;
    src: local('Signika-Bold'), url(../fonts/chart_signika_700.woff) format('woff');
}