@import "../base";

.waves-effect {
	position: relative;
	cursor: pointer;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;

	.waves-ripple {
		position: absolute;
		border-radius: 50%;
		width: 100px;
		height: 100px;
		margin-top:-50px;
		margin-left:-50px;
		//opacity: 1;
		background: rgba(0,0,0,0.1);
		/*$gradient: rgba(0,0,0,0.2) 0,rgba(0,0,0,.3) 40%,rgba(0,0,0,.4) 50%,rgba(0,0,0,.5) 60%,rgba(255,255,255,0) 70%;
		background: -webkit-radial-gradient($gradient);
		background: -o-radial-gradient($gradient);
		background: -moz-radial-gradient($gradient);
		background: radial-gradient($gradient);*/
		/*@include transition(all 0.4s ease-out);
		-webkit-transition-property: -webkit-transform, opacity;
		-moz-transition-property: -moz-transform, opacity;
		-o-transition-property: -o-transform, opacity;
		transition-property: transform, opacity;*/
		@include transform(scale(0) translate(0,0));
		pointer-events: none;
	}

	&.waves-light .waves-ripple {
		background: rgba(255,255,255,0.2);
		/*$gradient: rgba(255,255,255,0.2) 0,rgba(255,255,255,.3) 40%,rgba(255,255,255,.4) 50%,rgba(255,255,255,.5) 60%,rgba(255,255,255,0) 70%;
		background: -webkit-radial-gradient($gradient);
		background: -o-radial-gradient($gradient);
		background: -moz-radial-gradient($gradient);
		background: radial-gradient($gradient);*/
	}

	&.waves-classic .waves-ripple {
		background: rgba(0,0,0,0.2);
	}

	&.waves-classic.waves-light .waves-ripple {
		background: rgba(255,255,255,0.4);
	}
}

.waves-notransition {
	@include transition(none #{"!important"});
}

.waves-button,
.waves-circle {
	@include transform(translateZ(0));
	//-webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.waves-button,
.waves-button:hover,
.waves-button:visited,
.waves-button-input {
	white-space: nowrap;
	vertical-align: middle;
	cursor: pointer;
	border: none;
	outline: none;
	color: inherit;
	background-color: rgba(0, 0, 0, 0);
	font-size: 1em;
	line-height:1em;
	text-align: center;
	text-decoration: none;
	z-index: 1;
}

.waves-button {
	padding: 0.85em 1.1em;
	border-radius: 0.2em;
}

.waves-button-input {
	margin: 0;
	padding: 0.85em 1.1em;
}

.waves-input-wrapper {
	border-radius: 0.2em;
	vertical-align: bottom;

	&.waves-button {
		padding: 0;
	}

	.waves-button-input {
		position: relative;
		top: 0;
		left: 0;
		z-index: 1;
	}
}


/* Firefox Bug: link not triggered */
a.waves-effect .waves-ripple {
	z-index: -1;
}
