@import "../base";

.jquery-tree {
    list-style: none;
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
    width: 100%;
    padding-left: 0;
}

.jquery-tree ul {
    list-style: none;
    padding: 0;
}

.jquery-tree label{
    margin: 0;
}

.jquery-tree input[type="checkbox"]{
    margin: 0 3px 0 0;
}

.jquery-tree-handle {
    cursor: pointer;
    width: 10px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0;
}

.jquery-tree-collapsed ul {
    display: none;
}

.jquery-tree-collapseall,
.jquery-tree-expandall {
    cursor: pointer;
}

.jquery-tree-collapseall {
    margin-right: 1em;
}

.jquery-tree-leaf {
    padding-left: 20px;
}

.jquery-tree-node {
    padding-left: 10px;
}

.jquery-tree-title {
    display:inline-block;
}

.jquery-tree-node label,
.jquery-tree-handle {
    @include no-select;
    font-weight: normal;
    vertical-align: middle;
}
.jquery-tree-node label{
    padding-left: 16px;
}

.jquery-tree-node label i{
    margin-left: -16px;
}

.transport-tree-icon:before{
    content: url("../../img/icons/t_tree.png");
}

.zone-tree-icon:before{
    content: url("../../img/icons/z_tree.png");
}